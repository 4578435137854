import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button, Form, Alert, Spinner } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import './OrderConfirmationModal.css';
import axios from 'axios';

const OrderConfirmationModal = ({ servicesData, convertTime, currencySymbols, showOrderConfirmation, setShowOrderConfirmation, lastSuccessfulOrderDetails, mongoURI }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [emailSending, setEmailSending] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    if(emailSent) {
      setTimeout(() => {
        setShowOrderConfirmation(false);
      }, 3000);
    }
  }, [emailSent]);

  useEffect(() => {
    inputRef.current.focus();
    inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }, []);

  const handleSendReceipt = async (e) => {
    e.preventDefault();
    if (email.trim() === '') {
      setEmailError('Email is required for receipt.');
      return;
    }
    setEmailSending(true);
    setEmailError('');
    try {
      const response = await axios.post(`${mongoURI}sendConfirmationEmail`, {
        orderId: lastSuccessfulOrderDetails.id,
        email,
      });
      if (response.status === 200) {
        setEmailSent(true);
      } else {
        setEmailError('Failed to send receipt, please try again.');
      }
    } catch (error) {
      setEmailError('Error sending receipt, please try again.');
    } finally {
      setEmailSending(false);
    }
  };

  const handleClose = (whatTodo) => {
    if (emailSent) {
      setShowOrderConfirmation(false);
    } else {
      setEmailError('Email is required for receipt.');
      inputRef.current.focus();
    }
  };

  const handleSkipReceipt = () => {
    setShowOrderConfirmation(false);
  };

  return (
    <Modal show={showOrderConfirmation} onHide={handleClose} centered>
      <Modal.Header closeButton className="modal-header-custom">
        <Modal.Title className="modal-title-custom">Order Confirmed</Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-body-custom">
        <div className="confirmation-content mb-3">
          <FaCheckCircle size={80} className="confirmation-icon" />
          <h3 className="mt-3 text-primary">Thank you! Your order has been placed.</h3>
          {lastSuccessfulOrderDetails && servicesData[lastSuccessfulOrderDetails.platform][lastSuccessfulOrderDetails.service]?.average_time>0 && (
            <p className="mt-2 estimated-time">Estimated Time: {convertTime(servicesData[lastSuccessfulOrderDetails.platform][lastSuccessfulOrderDetails.service].average_time)}</p>
          )}
<div className="order-details mb-3">
  <table className="order-details-table">
    <tbody>
    <tr>
        <td><strong>Platform:</strong></td>
        <td>{lastSuccessfulOrderDetails?.platform}</td>
      </tr>
      <tr>
        <td><strong>Service:</strong></td>
        <td>{lastSuccessfulOrderDetails?.quantity > 1 && lastSuccessfulOrderDetails?.quantity} {lastSuccessfulOrderDetails?.service}</td>
      </tr>
      <tr>
        <td><strong>Username/URL:</strong></td>
        <td>{lastSuccessfulOrderDetails?.usernameOrLink}</td>
      </tr>
      <tr>
        <td><strong>Total Paid:</strong></td>
        <td>{(currencySymbols[lastSuccessfulOrderDetails?.currency] || lastSuccessfulOrderDetails?.currency)}{lastSuccessfulOrderDetails?.priceAfterDiscount}</td>
      </tr>
    </tbody>
  </table>
</div>


          <Form onSubmit={handleSendReceipt} className="mb-3">
            <Form.Group className="mb-2">
              {!emailSent && <Form.Control
                type="email"
                placeholder="Enter email for receipt"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                  setEmailError('');
                  setEmailSent(false);
                }}
                required
                className={`email-input ${emailError ? 'is-invalid' : ''}`}
                ref={inputRef}
              />}
              {emailError && <div className="invalid-feedback">{emailError}</div>}
            </Form.Group>
            <Button
              variant="success"
              type="submit"
              className="w-100"
              disabled={emailSent || emailSending}
            >
              {emailSending ? <Spinner as="span" animation="border" size="sm" /> : emailSent ? `Your receipt has been sent to ${email}.` : 'Send Receipt to My Email'}
            </Button>
          </Form>

          {emailError && (
            <Button
              variant="link"
              className="mt-3 no-receipt-btn"
              onClick={handleSkipReceipt}
            >
              I don't need a receipt
            </Button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default OrderConfirmationModal;
