import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form, FloatingLabel, OverlayTrigger, Tooltip } from 'react-bootstrap';
import axios from 'axios';
import './Hero.css';
import Payment from './Payment';
import timeIcon from '../assets/time.png';

const Hero = ({ convertTime, api, country, currency, currencySymbols, couponIcon, platform, handlePlatformSelect, servicesData, platformLogos, serviceIcon, urlImg, quantityImg, setLastSuccessfulOrderDetails, setShowOrderConfirmation, currencyConversionRates, mongoURI, quantityRef }) => {
  const [service, setService] = useState('');
  const [quantity, setQuantity] = useState('');
  const [usernameOrLink, setUsernameOrLink] = useState('');
  const [convertedPrice, setConvertedPrice] = useState({});
  const [showPayment, setShowPayment] = useState(false);
  const [orderPlaceLoading, setOrderPlaceLoading] = useState(false);
  const [amount, setAmount] = useState(0);
  const [order, setOrder] = useState();
  const [showReferralInput, setShowReferralInput] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const [discount, setDiscount] = useState(0);
  const [referralLabel, setReferralLabel] = useState({ text: 'Referral Code (Optional)', color: 'black' });
  const [quantityValid, setQuantityValid] = useState(false);

  const serviceRef = useRef(null);

  useEffect(() => {
    if (platform) {
      setService(Object.keys(servicesData[platform])[0]);
    }
  }, [platform]);
  

  useEffect(() => {
    if (service) {
      let maxQuantity = servicesData[platform][service]?.max;
      let minQuantity = servicesData[platform][service]?.min;
      // let reasonableQuantity = Math.ceil((maxQuantity - minQuantity) / 100);
      // setQuantity(reasonableQuantity < minQuantity ? minQuantity : reasonableQuantity);
      setQuantity(minQuantity);
      quantityRef.current.focus();
      quantityRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      setQuantity('');
    }
  }, [service,platform]);

  useEffect(() => {
    if (service && quantity) {
      updatePrices(service, quantity);
      let min = servicesData[platform][service]?.min;
      let max = servicesData[platform][service]?.max;
      if (quantity >= min && quantity <= max) {
        setQuantityValid(true);
      } else {
        setQuantityValid(false);
      }
    } else {
      setQuantityValid(false);
    }
  }, [quantity,currencyConversionRates]);

  useEffect(() => {
    handleReferralCodeChange();
  }, [referralCode]);

  useEffect(() => {
    if(service && quantity) updatePrices();
  }, [discount]);

  const updatePrices = () => {
    setShowPayment(false);
  
    if (!quantity || !service) {
      setConvertedPrice({});
      return;
    }
  
    const baseRate = servicesData[platform][service]?.rate;
  
    // Calculate price with referral discount if any
    let originalPrice = baseRate * quantity;
  
    if (discount) {
      originalPrice = originalPrice - (originalPrice * discount * 0.01);
    }
  
    const updatedPrices = {};
    for (const [key, rate] of Object.entries(currencyConversionRates)) {
      updatedPrices[key] = originalPrice > 0 ? (originalPrice * rate).toFixed(2) : '';
    }
  
    setConvertedPrice(updatedPrices);
  };
  

  const handleUrlChange = (e) => {
    setUsernameOrLink(e.target.value);
    setShowPayment(false);
  };

  const handleReferralCodeChange = async () => {
    if (!referralCode) {
      setDiscount(0);
      setReferralLabel({ text: 'Referral Code (Optional)', color: 'black' });
      return;
    }
    if (referralCode.length === 3) {
      setReferralLabel({ text: 'Validating referral code...', color: 'blue' });
      try {
        const response = await axios.post(`${mongoURI}validateReferralCode`, { referralCode });
        if (response.data.valid) {
          setDiscount(response.data.discount);
          setReferralLabel({ text: `${response.data.discount}% discount applied!`, color: 'green' });
        } else {
          setDiscount(0);
          setReferralLabel({ text: 'Referral Code Not Valid', color: 'red' });
        }
      } catch (error) {
        setDiscount(0);
      }
    } else {
      setReferralLabel({ text: 'Referral Code Not Valid', color: 'red' });
      setDiscount(0);
    }
  };

  const handleReferralLinkClick = (e) => {
    e.preventDefault();
    setShowReferralInput(true);
  };

  const handlePlaceOrder = async (e) => {
    e.preventDefault();
    setOrderPlaceLoading(true);
    if (platform && service && quantity && usernameOrLink) {
      try {
        const orderData = {
          platform,
          service,
          serviceCode: servicesData[platform][service].code,
          quantity,
          usernameOrLink,
          currency,
          priceAfterDiscount: `${convertedPrice[currency]}`
        };
        if (discount > 0) {
          orderData.referralCode = referralCode;
          orderData.discount = discount;
        }

        const res = await axios.post(`${mongoURI}createOrder`, orderData);
        orderData.id = res.data;
        setOrder(orderData);
        const calculatedAmount = convertedPrice[currency] * 100;
        setAmount(calculatedAmount);
        setOrderPlaceLoading(false);
        setShowPayment(true);
        if (!discount) {
          setShowReferralInput(false);
        }
      } catch (error) {
        alert('There was an error creating your order. Please try again.');
        setOrderPlaceLoading(false);
      }
    } else {
      alert('Please fill in all required fields.');
      setOrderPlaceLoading(false);
    }
  };

  const handlePaymentSuccess = async (paymentIntent) => {
    setLastSuccessfulOrderDetails(order);
    setShowPayment(false);
    setShowOrderConfirmation(true);
    setService('');
    try {
      await axios.post(`${mongoURI}updateOrderStatusForNewOrder`, {
        paymentIntentId: paymentIntent.id,
        orderId: order.id
      });
    } catch (error) {
      alert("Error updating order status. Please contact support.");
    }
    if (!order.serviceCode.includes('P_')) {
      try {
        await axios.post(api, {
          task: 'placeOrder',
          order,
          paymentIntentId: paymentIntent.id,
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  function getTomorrowDate() {
    const monthNames = ["January", "February", "March", "April", "May", "June",
      "July", "August", "September", "October", "November", "December"];

    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1); // Set to tomorrow

    let day = String(tomorrow.getDate()).padStart(2, '0'); // Get day and format with leading zero if needed
    let month = monthNames[tomorrow.getMonth()]; // Get month name

    return `${day} ${month}`;
  }

  return (
    <div className="hero-section" id="hero">
      <Container>
        <Row>
          <Col md={8} className="mx-auto">
          <h1><span className="bounce">🚀</span> Instant Social Media Growth– Start Now! <span className="burn">🔥</span></h1>
          <p>⚡ <span className="glow">Limited Time Offer! Grab These Exclusive Prices Before They're Gone ⏳</span></p>
          {/* - <span class="nowrap">{ getTomorrowDate() }</span>  */}
            <Form onSubmit={handlePlaceOrder}>
              <div className="d-flex align-items-center mb-3">
                {platform && <img src={platformLogos[platform]} alt={platform} className="platform-icon-small me-2" />}
                <Form.Select value={platform || ''} onChange={(e) => handlePlatformSelect(e.target.value)} className="form-control">
                  {!platform && <option value="">Loading Platforms..</option>}
                  {Object.keys(servicesData || {}).map((platformKey) => (
                    <option key={platformKey} value={platformKey}>
                      {platformKey}
                    </option>
                  ))}
                </Form.Select>
              </div>

              {platform && (
                <div className={`mb-3 ${servicesData[platform] && servicesData[platform][service] && servicesData[platform][service]?.description && 'selector-with-description'}`}>
                  <div className="d-flex align-items-center">
                    <img src={serviceIcon} alt={service} className="platform-icon-small me-2" />
                    <div className="w-100"> {/* Wrapping the select and description */}
                      <Form.Select
                        value={service || ''}
                        onChange={(e) => setService(e.target.value)}
                        className="form-control"
                        ref={serviceRef}
                      >
                        {!service && <option value="">Select Service</option>}
                        {Object.keys(servicesData[platform] || {}).map((serviceKey) => (
                          <option key={serviceKey} value={serviceKey}>
                            {serviceKey}
                          </option>
                        ))}
                      </Form.Select>

                      {/* Service Description, visually connected to the select box */}
                      {service && servicesData[platform][service]?.description && (
                        <div className="service-description-container">
                          {servicesData[platform][service].description}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {service && (
                <div className="d-flex align-items-center mb-3">
                  <img src={quantityImg} alt="Quantity" className="platform-icon-small me-2" />
                  <Form.Control
                    type="number"
                    placeholder="Enter Quantity"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                    ref={quantityRef}
                    required
                    className={`${!quantityValid && quantity ? 'error-border' : ''}`}
                  />
                  {servicesData[platform][service]?.average_time > 0 && (
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="averageTimeTooltip">
                          Average Time (based on last 10 completed orders): {convertTime(servicesData[platform][service]?.average_time)}
                        </Tooltip>
                      }
                    >
                      <span className="ms-2">
                        <img src={timeIcon} className="platform-icon-small me-2" style={{ cursor: 'pointer' }} />
                      </span>
                    </OverlayTrigger>
                  )}
                </div>
              )}
              {!quantityValid && quantity && (
                <div className="quantity-error">
                  Please enter a quantity between {servicesData[platform][service]?.min} and {servicesData[platform][service]?.max}.
                </div>
              )}
              {quantityValid && (
                <div className="d-flex align-items-center mb-3">
                  <img src={urlImg} alt="Username/Link" className="platform-icon-small me-2" />
                  <Form.Control
                    type="text"
                    placeholder={servicesData[platform][service]?.link || `Enter Username or Link`}
                    value={usernameOrLink}
                    onChange={handleUrlChange}
                    required
                    className="form-control"
                  />
                </div>
              )}

              {!showReferralInput && !showPayment && (
                <div className="mb-2 text-center">
                  <a href="#" className="referral-link small" onClick={handleReferralLinkClick}>
                    Have a referral code?
                  </a>
                </div>
              )}

              {showReferralInput && quantityValid && (
                <div className="d-flex align-items-center mb-3">
                  <img src={couponIcon} alt="Referral Code" className="platform-icon-small me-2" />
                  <FloatingLabel controlId="referralCodeInput" label={referralLabel.text} className={`flex-grow-1 ${referralLabel.color}Text`}>
                    <Form.Control
                      type="text"
                      placeholder={referralLabel.text}
                      value={referralCode}
                      onChange={(e) => setReferralCode(e.target.value)}
                      className={`${referralLabel.color}Text`}
                    />
                  </FloatingLabel>
                </div>
              )}
              {quantityValid && currencyConversionRates && Number(convertedPrice['USD'])<0.8 && (
                <div className="quantity-error" style={{fontSize:"1rem"}}>
                  Total needs to be <strong>at least {`${currencySymbols[currency]}${Number(currencyConversionRates[currency]*0.8).toFixed(2)}`}</strong>. Please increase the quantity{discount && ` or remove the discount`}.
                </div>
              )}
              {quantityValid && !showPayment && (
                <div className="d-flex justify-content-center mb-3">
                  <button
                    type="submit"
                    className="btn submitButton"
                    disabled={orderPlaceLoading || Number(convertedPrice['USD'])<0.8}
                  >
                    {orderPlaceLoading ? 'Processing Order...' : (convertedPrice[currency])?`Place Order (${currencySymbols[currency]}${(convertedPrice[currency])?.toLocaleString()})`:'Place Order'}
                  </button>
                </div>
              )}

              {showPayment && amount && (
                <Payment
                  currencySymbols={currencySymbols}
                  amount={amount}
                  currency={currency}
                  onPaymentSuccess={handlePaymentSuccess}
                  onClose={() => setShowPayment(false)}
                  mongoURI={mongoURI}
                  country={country}
                />
              )}
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Hero;
