import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import './App.css';
import Header from './components/Header';
import Hero from './components/Hero';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import OrderConfirmationModal from './components/OrderConfirmationModal';
import AdminOrders from './components/AdminOrders';
import OrderTracking from './components/OrderTracking';
import FooterIcon from './components/FooterIcon';
import Login from './components/Login';
import Chatbot from './components/Chatbot';
import AdminChat from './components/AdminChat';
import CustomOrder from './components/CustomOrder';
import ReviewComponent from './components/ReviewComponent';

import serviceIcon from './assets/serviceIcon.png';
import urlImg from './assets/web.png';
import quantityImg from './assets/quantity.png';
import HighlightsSection from './components/HighlightsSection';
import emailIcon from './assets/email.png';
import couponIcon from './assets/coupon.png';

import InstagramLogo from './assets/platformLogos/Instagram.png';
import FacebookLogo from './assets/platformLogos/Facebook.png';
import YoutubeLogo from './assets/platformLogos/Youtube.png';
import TikTokLogo from './assets/platformLogos/Tiktok.png';
import SpotifyLogo from './assets/platformLogos/Spotify.png';
import XLogo from './assets/platformLogos/X.png';
import LinkedInLogo from './assets/platformLogos/Linkedin.png';
import ReviewsLogo from './assets/platformLogos/Reviews.png';
import AppInstall from './assets/platformLogos/AppInstall.png';
import whyIcon from './assets/why.png';

import currencySymbols from './assets/currencySymbols.json';
import countryToCurrency from './assets/countryToCurrency.json';
import CustomOrderOffsite from './components/CustomOrderOffsite';
import servicesData1 from './assets/servicesCodes.json'

const platformLogos = {
  Instagram: InstagramLogo,
  Facebook: FacebookLogo,
  Youtube: YoutubeLogo,
  TikTok: TikTokLogo,
  Spotify: SpotifyLogo,
  X: XLogo,
  LinkedIn: LinkedInLogo,
  'Reviews And Ratings': ReviewsLogo,
  'App Installs': AppInstall,
};

const mongoURI = process.env.REACT_APP_MONGOURI;
const stripePromise = loadStripe(process.env.REACT_APP_STRIPEPK);
const api = `${window.location.origin}/api`;

function App() {
  const [authToken, setAuthToken] = useState(localStorage.getItem('authToken'));
  const [platform, setPlatform] = useState(null);
  const [lastSuccessfulOrderDetails, setLastSuccessfulOrderDetails] = useState(null);
  const [showOrderConfirmation, setShowOrderConfirmation] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [currencyConversionRates, setCurrencyConversionRates] = useState({});
  const [servicesData, setServicesData] = useState({});
  const [country, setCountry] = useState('');
  const [currency, setCurrency] = useState(null);
  const quantityRef = useRef(null);
  // const [fetchedServiceCodes, setFetchedServiceCodes] = useState(false);

  const handlePlatformSelect = (newPlatform) => {
    if (newPlatform) {
      setPlatform(newPlatform);
      if (newPlatform == platform && quantityRef) {
        quantityRef.current.focus();
        quantityRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };

  const convertTime = (minutes) => {
    if (minutes >= 1440) {
      const days = Math.floor(minutes / 1440);
      return `${days} day${days > 1 ? 's' : ''}`;
    } else if (minutes >= 60) {
      const hours = Math.floor(minutes / 60);
      return `${hours} hour${hours > 1 ? 's' : ''}`;
    } else {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    }
  };

  const getCurrencyConversionRates = () => {
    axios.get('https://api.exchangerate-api.com/v4/latest/USD')
      .then(response => {
        response.data.rates.USD = 1;
        setCurrencyConversionRates(response.data.rates);
      })
      .catch(error => {
        console.error('Error fetching currency conversion rates:', error);
      });
  }

  const getCountry = () => {
    axios.get(`${mongoURI}geolocation`)
      .then((response) => {
        const countryCode = response.data.countryCode;
        const userCurrency = countryToCurrency[countryCode];  // Map country to currency
        setCountry(countryCode);
        setCurrency(userCurrency || 'USD');  // Fallback to USD only if userCurrency is not available
      })
      .catch((error) => {
        console.error('Error fetching location:', error);
        setCurrency('USD');  // Fallback if API call fails
      });
  };

  const saveServices = async () => {
    try {
      const response = await axios.post(api, { task: "services", action: 'services', serviceCodes: servicesData1 });

      const services = { services: response.data[0] };

      // Save the services to MongoDB
      const response1 = await axios.post(`${mongoURI}saveServicesData`, services);

      console.log('Saved services to MongoDB:', response1.data);
    } catch (error) {
      console.error('Error saving services:', error);
    }
  };


  useEffect(() => {
    const getServicesDataOld = async () => {
      try {
        const response = await axios.get(`${mongoURI}getServicesData`);
        setServicesData(response.data.findResult);
        // setFetchedServiceCodes(true);
      }
      catch (error) {
        console.error('Error fetching services:', error);
      }
    }
    getServicesDataOld();
    // saveServices();
    getCountry();
    getCurrencyConversionRates();
  }, []);

  useEffect(() => {
    if (servicesData) {
      setPlatform(Object.keys(servicesData)[0])
    }
  }, [servicesData])

  return (
    <Router>
      <Header mongoURI={mongoURI} setIsChatOpen={setIsChatOpen} handlePlatformSelect={handlePlatformSelect} authToken={authToken} setAuthToken={setAuthToken} />
      <div className='main'>
        <Elements stripe={stripePromise}>
          <Routes>
            <Route path="/" element={
              <>
                <Hero
                  platform={platform}
                  handlePlatformSelect={handlePlatformSelect}
                  servicesData={servicesData}
                  platformLogos={platformLogos}
                  serviceIcon={serviceIcon}
                  urlImg={urlImg}
                  quantityImg={quantityImg}
                  setLastSuccessfulOrderDetails={setLastSuccessfulOrderDetails}
                  setShowOrderConfirmation={setShowOrderConfirmation}
                  currencyConversionRates={currencyConversionRates}
                  mongoURI={mongoURI}
                  couponIcon={couponIcon}
                  currencySymbols={currencySymbols}
                  country={country}
                  currency={currency}
                  api={api}
                  convertTime={convertTime}
                  quantityRef={quantityRef}
                />
                <Services
                  handlePlatformSelect={handlePlatformSelect}
                  servicesData={servicesData}
                  platformLogos={platformLogos}
                />
                <Testimonials handlePlatformSelect={handlePlatformSelect} platform={platform} />
                <HighlightsSection />
              </>
            } />
            <Route path="/admin" element={
              !authToken ? (
                <Login setAuthToken={setAuthToken} mongoURI={mongoURI} />
              ) : (
                <AdminOrders mongoURI={mongoURI} currencyConversionRates={currencyConversionRates} />
              )
            } />
            <Route path="/order-tracking" element={
              <OrderTracking
                servicesData={servicesData}
                platformLogos={platformLogos}
                urlImg={urlImg}
                emailIcon={emailIcon}
                mongoURI={mongoURI}
                convertTime={convertTime}
              />
            } />
            <Route path="/review" element={
              <ReviewComponent mongoURI={mongoURI}/>
            } />
            <Route path="/admin-reply" element={
              <AdminChat mongoURI={mongoURI} />
            } />
            <Route path="/custom-order" element={
              <CustomOrder
                currencyConversionRates={currencyConversionRates}
                mongoURI={mongoURI}
                currencySymbols={currencySymbols}
                country={country}
                currency={currency}
                couponIcon={couponIcon}
                emailIcon={emailIcon}
                whyIcon={whyIcon}
              />
            } />
            <Route path="/offsite-order"
              // element={
              //   <CustomOrderOffsite
              //     currency={currency}
              //   />
              // }
              element={
                <CustomOrder
                  currencyConversionRates={currencyConversionRates}
                  mongoURI={mongoURI}
                  currencySymbols={currencySymbols}
                  country={country}
                  currency={currency}
                  couponIcon={couponIcon}
                  emailIcon={emailIcon}
                  whyIcon={whyIcon}
                />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Elements>
      </div>
      {
        showOrderConfirmation &&
        <OrderConfirmationModal
          showOrderConfirmation={showOrderConfirmation}
          lastSuccessfulOrderDetails={lastSuccessfulOrderDetails}
          setShowOrderConfirmation={setShowOrderConfirmation}
          mongoURI={mongoURI}
          currencySymbols={currencySymbols}
          setIsChatOpen={setIsChatOpen}
          convertTime={convertTime}
          servicesData={servicesData}
        />
      }
      <Footer setIsChatOpen={setIsChatOpen} handlePlatformSelect={handlePlatformSelect} />
      <Chatbot mongoURI={mongoURI} isChatOpen={isChatOpen} setIsChatOpen={setIsChatOpen} />
      {!isChatOpen && <FooterIcon setIsChatOpen={setIsChatOpen} />}
    </Router>
  );
}

export default App;
