import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import './ReviewComponent.css';
import axios from 'axios';

const ReviewComponent = ({ mongoURI }) => {
  const location = useLocation();
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');
  const [submitted, setSubmitted] = useState(false);

  const params = new URLSearchParams(location.search);
  const email = params.get('email');

  const handleRating = (rate) => {
    setRating(rate);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const reviewData = { email, rating, review };

    try {
      await axios.post(`${mongoURI}review`, { reviewData });
      setSubmitted(true);
    } catch (error) {
      console.error('Error submitting review:', error);
    }
  };

  if (submitted) {
    return (
      <div className="review-confirmation">
        <div className="confirmation-message">
          <h2>Thank You for Your Feedback!</h2>
          <p>
            As a token of our appreciation, use code <span className='green'><strong>D10</strong></span> for 10% off your next order.
          </p>
          <button
            className="new-order-button"
            onClick={() => (window.location.href = '/')}
          >
            Place a New Order
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="review-component">
      <h2>We Value Your Feedback!</h2>
      <p>Please rate your experience:</p>
      <div className="rating-stars">
        {[1, 2, 3, 4, 5].map((star) => (
          <span
            key={star}
            className={`star ${star <= rating ? 'selected' : ''}`}
            onClick={() => handleRating(star)}
          >
            ★
          </span>
        ))}
      </div>
      <form onSubmit={handleSubmit} className="review-form">
        <textarea
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Write your review here..."
          rows="4"
          className="review-textarea"
        />
        <button type="submit" className="submit-button" disabled={rating === 0}>
          Submit
        </button>
      </form>
    </div>
  );
};

export default ReviewComponent;